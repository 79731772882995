import React, {useEffect, useState} from 'react';
import {Select} from "components/Select";
import {SetCookieVisibleColumn} from "../components/Table/setCookieVisibleColumn";

const MultiSelectColumns = ({allColumns, dataName}) => {
  const [value, setValue] = useState([]);
  const [allOptions, setAllOptions] = useState({});


  useEffect(() => {

    let selectedOptions = [];
    let allOptionsObj = {};

    allColumns.forEach(column => {
      let item = {
        label: column.Header,
        value: column.id,
        ...column,
      }
      allOptionsObj[item.value] = item

      if (column.isVisible) {
        selectedOptions.push(item);
      }
    });

    setValue(selectedOptions);
    setAllOptions(allOptionsObj);

  }, [])


  const onHandleChange = (e, inputObj) => {
    setValue([...e]);

    if (inputObj.removedValue) {
      SetCookieVisibleColumn(dataName, inputObj.removedValue.id)
      inputObj.removedValue.toggleHidden(true);
    }

    if (inputObj.action === "select-option") {
      SetCookieVisibleColumn(dataName, inputObj.option.id)
    }

    e.map((item) => {
      item.toggleHidden(false);
      return {...item}
    });

  }

  return (
    <div>
      <Select
        className={"small"}
        isMulti={true}
        disabled={false}
        options={Object.values(allOptions)}
        value={value}
        onChange={(e, inputObj) => {
          onHandleChange(e, inputObj);
        }}
      />

    </div>
  );
};

export default MultiSelectColumns;