import * as CryptoJS from 'crypto-js';
import swal from "sweetalert";
import {authConstants} from "../constants/authConstants";
import i18next from "i18next";

export const handlerErrorMessage = (status, message = "", isRTK = false) => {
  console.log("status", status)
  swal({
    icon: "error",
    text: i18next.t(`backCodeError.${status.toString()}`, {value: message}),
  });
  return false
}

export function setCookie(name, value, options = {}) {

  options = {
    path: process.env.COOKIE_PATH || "/",
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
}

export function getCookie(cName) {
  let name = cName + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const dataEncryption = (data) => {
  return CryptoJS.AES.encrypt(data, process.env.criptoKey).toString();
}

export const requestURl = process.env.REQUEST_URl;
// export const requestURl = "http://185.132.177.102:3031";
console.log("requestURl", requestURl)
console.log("PUBLIC_URL", process.env.PUBLIC_URL)
export const domainCookie = window.location.hostname;
export const setLoginCookie = (response) => {
  // console.log("response", response)
  let options = {
    domain: domainCookie
  }
  setCookie(authConstants.accessToken, response.accessToken, options);
  setCookie(authConstants.refreshToken, response.refreshToken, options);
  setCookie(authConstants.typeToken, response.type, options);
  setCookie(authConstants.userId, response.userId, options);
  setCookie(authConstants.language, response.language?.toLowerCase(), options);
  setCookie(authConstants.hasPrivilege, response.hasPrivilege, options);
}

export const delLoginCookie = () => {
  let cookieArr = [
    authConstants.accessToken,
    authConstants.refreshToken,
    authConstants.typeToken,
    authConstants.userId,
    authConstants.language,
    authConstants.hasPrivilege
  ];
  let options = {
    domain: domainCookie,
    'max-age': -1
  }
  let options2 = {
    domain: `.${domainCookie}`,
    'max-age': -1
  }
  let options3 = {
    domain: domainCookie,
    'max-age': -1,
    path: "/a"
  }
  let options4 = {
    domain: `.${domainCookie}`,
    'max-age': -1,
    path: "/a"
  }
  cookieArr.forEach(item => {
    setCookie(item, "", options);
    setCookie(item, "", options2);
    setCookie(item, "", options3);
    setCookie(item, "", options4);
  })
  // setCookie(authConstants.accessToken, "", options);
  // setCookie(authConstants.refreshToken, "", options);
  // setCookie(authConstants.typeToken, "", options);
  // setCookie(authConstants.userId, "", options);
  // setCookie(authConstants.language, "", options);
  // setCookie(authConstants.hasPrivilege, "", options);
}

export const requestRefreshToken = (url, method, requestBody, credentials, emptyResponse) => {
  const currentRefreshToken = getCookie(authConstants.refreshToken);

  const body = {
    refreshToken: currentRefreshToken
  }
  return fetch(`${requestURl}/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status === 401) {
      delLoginCookie();
      unAuthModal(url);
      return;
    }
    return response.json()
  })
    .then((response) => {
      if (response) {
        const currentRefreshToken = getCookie(authConstants.refreshToken);
        const body = {
          refreshToken: currentRefreshToken
        }
        return fetch(`${requestURl}/refresh`, {
          method: "POST",
          body: body ? JSON.stringify(body) : null,
          headers: {
            Authorization: response.type + " " + response.accessToken,
            "Content-Type": "application/json",
          }

        }).then((response) => {
          if (response.status === 401) {
            delLoginCookie();
            unAuthModal(url);
            return;
          }
          return response.json()
        })
          .then((response) => {
            if (response) {
              setLoginCookie(response);
              return api(url, {
                method: method,
                data: requestBody,
                credentials: credentials,
                emptyResponse
              }).then((res) => {
                return res;
              })
            }
          })
      } else {
        return true
      }


    })
}

export const unAuthModal = (url) => {
  // console.log("rul", url)
  // console.log("${process.env.PUBLIC_URL}/login", process.env.PUBLIC_URL)
  swal({
    icon: "warning",
    text: url === "/login" ? i18next.t("checkYourData") : i18next.t("relogin")
  }).then(() => {
    if (url !== "/login") {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
  })
}

// export const fetchGetPDF = (url, data) => {
//   let headers = getCookie(accessToken) && getCookie(accessToken) !== "undefined" ? {
//     "Content-Type": "application/json",
//     responseType: "blob",
//     Authorization: getCookie(typeToken) + " " + getCookie(accessToken),
//   } : {
//     "Content-Type": "application/json",
//     responseType: "blob",
//   }
//   return fetch(url, {
//     method: "POST",
//     body: JSON.stringify(data),
//     headers: headers
//   }).then(response => {
//     if (response.status === 403) {
//       return requestRefreshToken(url, "POST", data).then(res => {
//         return res;
//       });
//     }
//     if (response.status !== 200 && response.status !== 201) {
//       errorsHandler(response.status, response);
//       return
//     }
//     if (response.ok) {
//       return response.blob();
//     }
//   })
// }

export default function api(url, {
  method = "GET",
  data,
  credentials = "same-origin",
  contentType = "application/json",
  responseType = "",
  emptyResponse = false,
} = {}) {
  let accessToken = getCookie(authConstants.accessToken);
  let typeToken = getCookie(authConstants.typeToken);

  const headers = {
    "Content-Type": contentType,
    "Authorization": `${accessToken ? typeToken + " " + accessToken : ""}`,
    responseType,
  };

  const init = {
    method,
    body: data ? JSON.stringify(data) : null,
    credentials,
    headers
  }

  return fetch(
    `${requestURl}${url}`,
    init
  ).then(async (response) => {
    if (response.status === 401 || (response.status === 403 && url === "/login")) {
      unAuthModal(url);
      return;
    }
    if (response.status === 403 && url !== "/login") {
      return requestRefreshToken(url, method, data, credentials, emptyResponse).then(res => {
        return res;
      })
    } else if (response.status >= 200 && response.status < 300 && responseType === "") {
      if (emptyResponse) {
        return response
      } else {
        return response.json();
      }

    } else if (response.status >= 200 && response.status < 300 && responseType === "blob") {
      return response.blob();
    } else {
      // обработка наших ошибок
      let result = await response.json();
      handlerErrorMessage(result.status, result?.message);
      return;
    }

  });
}
