import {createSlice} from "@reduxjs/toolkit";
import {apiUrls, filterPaymentsType} from "../../constants/Constants";

import api from "utils/api";
import {typePages} from "constants/typesConstants";
import moment from "moment";
import React from "react";
import {adStatusFormatter} from "../../utils/accountStatusFormatter";
import {ServiceIdType, ServiceText, ServiceTypesImg} from "../../common/seriveTypes";
import {ReplaceDotToComma} from "../../utils/sumValidation";
import {docTypeFormatter} from "../../utils/docTypeFormatter";
import {DataFormatter} from "../../utils/OperationTypeFormatter";
import {statusFormatter} from "../../routes/RequestFB";
import {
  DDMMYYYYFormat,
  DDMMYYYYFullFormat,
  FormatterDateFromTo,
  YYYYMMDDFormat,
  YYYYMMDDFullFormat
} from "../../utils/date";
import {FormatterTypeReplenishment} from "../../utils/Formatters/FormatterTypeReplenishment";
import i18next from "i18next";
import {
  FinApplicationsStatusFormatter,
  FinApplicationTypeFormatter
} from "../../utils/Formatters/FinApplicationsFormatter";
import {countryFormatter} from "../../common/regionType";
import i18n from "i18next";


export const tableDataSlice = createSlice({
  name: "tableData",
  initialState: {
    users: [],
    clients: [],
    filter: filterPaymentsType.ALL,
    filtredSelectData: {},
    isLoad: false,
    isRefreshLoad: false,
    searchableField: "",
    bonuses: [],
    showCommissionModal: false,
    showBonusesModal: false,
  },
  reducers: {
    setTableData: (state, {payload}) => {
      return {...state, ...payload};
    },
  },
});

export const {setTableData} = tableDataSlice.actions;


export const loadTableData = (type, body) => (dispatch) => {
  // console.log("type", type, body);
  switch (type) {
    case "businessCenterId":
      dispatch(setTableData({isLoad: true}));
      return api("/tik-tok/center/all", {method: "GET"})
        .then((res) => {
          let data = Array.isArray(res) ? res?.map(item => {
            return {
              ...item,
              value: item.id,
              label: `${item.name}     ${item.businessCenterId}`,
            }
          }) : [];
          dispatch(setTableData({[type]: data}));
          return data
        }).finally(() => dispatch(setTableData({isLoad: false})));

    case "businessCenterIdYandex":
      ///yandex/center/all
      dispatch(setTableData({isLoad: true}));
      return api("/yandex/center/all", {method: "GET"})
        .then((res) => {
          let data = Array.isArray(res) ? res?.map(item => {
            return {
              ...item,
              value: item.id,
              label: `${item?.alphabeticCode}  ${item.name}     ${item.businessCenterId}`,
            }
          }) : []
          dispatch(setTableData({[type]: data}));
          return data
        }).finally(() => dispatch(setTableData({isLoad: false})));

    case "generalCommissionRegions":
      dispatch(setTableData({isLoad: true}));
      const langRegion = i18n.language;
      return api("/commissions/no-general", {method: "GET"})
        .then(res => {
          res.status && dispatch(setTableData({[type]: res}));
          if (!res?.status) {
            const newData = res?.availableToCreate?.[body.serviceId]?.map((item) => {
                return {
                  ...item,
                  value: item.id,
                  label: langRegion === "ru" ? item.rusName : item.engName,
                }
              }
            );
            dispatch(setTableData({[type]: newData || []}));
            return newData
          }
          return res;
        })
        .finally(() => dispatch(setTableData({isLoad: false})));

    case "individualCommissionClients":
      dispatch(setTableData({isLoad: true}));
      return api("/clients/no-individual-commissions", {method: "GET"})
        .then(res => {
          res?.status && dispatch(setTableData({[type]: res}));
          // if (!res?.status) {
          //   const newData = res?.[body.serviceId].map((client) => {
          //       return {
          //         ...client,
          //         value: `${client.name}_${client.email}`,
          //         id: client.id,
          //         email: client.email,
          //         label: ClientOptionLabelFormatter(client),
          //         currency: client.currencyName
          //
          //       }
          //     }
          //   )
          //   dispatch(setTableData({[type]: newData}));
          //   return newData
          // }
          dispatch(setTableData({[type]: res}));
          return res;
        })
        .finally(() => dispatch(setTableData({isLoad: false})));

    case "clients_for_bonuses":
      dispatch(setTableData({clientsIsLoad: true}));
      // return api("/clients/get-all-clients", {
      return api("/clients/bonuses", {
        method: "GET",
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({clientsIsLoad: false}));
          dispatch(setTableData({[type]: tableData || []}));
          return tableData
        }).finally(() => dispatch(setTableData({clientsIsLoad: false})));

    case "commissionRegions":
      dispatch(setTableData({isLoad: true}));
      return api("/country/bonus-commission", {method: "GET"})
        .then(res => {
          Array.isArray(res) ? res?.map(item => {
            return {label: item.name, value: item.id}
          }) : []
          dispatch(setTableData({[type]: res}));
          dispatch(setTableData({isLoad: false}));
          return res
        });
    case "clients":
      dispatch(setTableData({clientsIsLoad: true}));
      // return api("/clients/get-all-clients", {
      return api("/clients", {
        method: "GET",
        // data: body,
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({clientsIsLoad: false}));
          let newData = tableData && tableData.map((item) => {
            return {
              ...item,
              clientType: item.clientType === "ROLE_CENTER_CLIENT" ? i18next.t("CC") : i18next.t("C"),
              clientTag: item.clientTag ? item.clientTag : "NONE"
              // label: <div className="d-flex justify-content-between">
              //   <div>{item.payLineName}</div>
              //   <div>{item.email}</div>
              // </div>,
              // value: `${item.id}_${item.email}_${item.payLineName}`
            }
          });
          return tableData && dispatch(setTableData({[type]: newData}));
        }).finally(() => dispatch(setTableData({clientsIsLoad: false})));

    case "clients_for_import_acc":
      dispatch(setTableData({clientsIsLoad: true}));
      // return api("/clients/get-all-clients", {
      const clientsForImportAccUrl = body.hasMcc ? `/clients/import-account?hasMcc=${body.hasMcc}` :
        `/clients/import-account?isFacebook=${body.isFacebook}`;
      return api(clientsForImportAccUrl, {
        method: "GET",
        // data: body,
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({clientsIsLoad: false}));
          dispatch(setTableData({[type]: tableData || []}));
          return tableData
        }).finally(() => dispatch(setTableData({clientsIsLoad: false})));

    case "clients_for_manual_deduct":
      dispatch(setTableData({clientsIsLoad: true}));
      // return api("/clients/get-all-clients", {
      // const clientsForImportAccUrl = body.hasMcc ? `/clients/import-account?hasMcc=${body.hasMcc}&isFacebook=false` :
      //   `/clients/import-account?isFacebook=${body.isFacebook}&hasMcc=false`;
      return api("/clients/manual-debiting", {
        method: "GET",
        // data: body,
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({clientsIsLoad: false}));
          dispatch(setTableData({[type]: tableData || []}));
          return tableData
        }).finally(() => dispatch(setTableData({clientsIsLoad: false})));

    case "accountsList":
      dispatch(setTableData({isLoad: true}));
      return api("/ad-account/all", {
        method: "GET"
      }).then(res => {
        if (res) {
          let newData = Array.isArray(res) ? res?.map(data => {
            return {
              ...data,
              accountId: data.adId,
              adId: data.serviceType === "F" ? "fb_" + data.adId :
                data.serviceType === "TT" ?
                  "tt_" + data.adId : data.adId,
              adStatus: adStatusFormatter(data.adStatus),
              isFirstCreationText: data?.isFirstCreation ? i18next.t("yes") : i18next.t("no"),
              cabinetType: data.cabinetType === "central" ? i18next.t("CC") : i18next.t("C"),
              serviceTypeText: data.serviceType === "G" ? "Google" :
                data.serviceType === "F" ? "Facebook" :
                  data.serviceType === "TT" ? "TikTok" :
                    data.serviceType === "YA" ? "Yandex" :
                      "new service",
              serviceTypeId: data.serviceType === "G" ? 1 :
                data.serviceType === "F" ? 3 :
                  data.serviceType === "TT" ? 4 :
                    data.serviceType === "YA" ? 5 : "",
              serviceImg: ServiceTypesImg(data.serviceType, false, "auto", 16, true),
              archivedText: data.archived ? i18next.t("yes") : i18next.t("no"),

              updatingDate: data.updatingDate ? moment(data.updatingDate, YYYYMMDDFullFormat).format(DDMMYYYYFullFormat) : "",
              creationDate: data.creationDate ? moment(data.creationDate, YYYYMMDDFullFormat).format(DDMMYYYYFullFormat) : "",
              lastDateExpense: data.lastDateExpense ? moment(data.lastDateExpense, YYYYMMDDFormat).format(DDMMYYYYFormat) : "",
            }
          }) : []
          return dispatch(
            setTableData({[type]: newData || []})
          );
        }
      }).finally(() => dispatch(setTableData({isLoad: false})));

    case "accountsForClient":
      dispatch(setTableData({isLoad: true}));
      return api(`/clients/card/accounts/${body.cabinetId}`, {
        method: "GET"
      }).then(res => {
        if (res) {
          let newData = Array.isArray(res) ? res?.map(data => {
            return {
              ...data,
              accountId: data.adId,
              adId: data.serviceType === "F" ? "fb_" + data.adId :
                data.serviceType === "TT" ?
                  "tt_" + data.adId : data.adId,
              adStatus: adStatusFormatter(data.adStatus),
              cabinetType: data.cabinetType === "central" ? i18next.t("CC") : i18next.t("C"),
              serviceTypeText: ServiceText(data.serviceType),
              serviceTypeId: ServiceIdType[ServiceText(data.serviceType)],
              serviceImg: ServiceTypesImg(data.serviceType, false, "auto", 16, true),
              archivedText: data.archived ? i18next.t("yes") : i18next.t("no"),

              updatingDate: data.updatingDate ? moment(data.updatingDate, YYYYMMDDFullFormat).format(DDMMYYYYFullFormat) : "",
              creationDate: data.creationDate ? moment(data.creationDate, YYYYMMDDFullFormat).format(DDMMYYYYFullFormat) : "",
              lastDateExpense: data.lastDateExpense ? moment(data.lastDateExpense, YYYYMMDDFormat).format(DDMMYYYYFormat) : "",
            }
          }) : [];
          return dispatch(
            setTableData({[type]: newData || []})
          );
        }
      }).finally(() => dispatch(setTableData({isLoad: false})));

    case "check-advName":
      dispatch(setTableData({isLoad: true}));
      return api(`/ad-account/check-adv-name`,
        {
          method: "POST",
          data: body
        }).then((response) => {
        dispatch(setTableData({isLoad: false}));
        if (response) {
          dispatch(setTableData({[type]: response}));
          return response
        }
      }).finally(() => dispatch(setTableData({isLoad: false})))
    case "manual_clients":
      dispatch(setTableData({isLoad: true}));
      return api("/clients/all-manual-fin-management", {
        method: "GET",
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({isLoad: false}));
          let newData = tableData && tableData.map((item) => {
            return {
              ...item,
              value: `${item.payLineName}_${item.email}`,
              id: item.cabinetId,
              email: item.email,
              label: (<div className={"d-flex justify-content-between"}>
                <div>{item.payLineName}</div>
                <div>{item.email}</div>
              </div>)
            }
          });
          return tableData && tableData.entries && dispatch(setTableData({[type]: newData}));
        });

    case "clients_with_blocked_acc":
      dispatch(setTableData({isLoad: true}));
      return api("/clients/get-clients-with-blocked-accounts", {
        method: "GET",
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({isLoad: false}));
          let newData = tableData && tableData.map((item) => {


            // {
            //   "id": 499,
            //   "email": "pm.adverton@gmail.com",
            //   "payLineName": "имбю",
            //   "walletCurrency": "USD"
            // }
            return {
              // {
              //   "cabinetId": 499,
              //   "email": "pm.adverton@gmail.com",
              //   "payLineName": "имбю",
              //   "walletCurrency": "USD"
              // },

              ...item,
              value: `${item.payLineName}_${item.email}`,
              id: item.cabinetId,
              email: item.email,
              // label: item.payLineName + " " + item.email,
              label: (<div className={"d-flex justify-content-between"}>
                <div>{item.payLineName}</div>
                <div>{item.email}</div>
              </div>)
            }
          });
          return tableData && tableData.entries && dispatch(setTableData({[type]: newData}));
        })
        .finally(() => dispatch(setTableData({isLoad: false})));

    case "fb-tt_acc-list":
      dispatch(setTableData({isLoad: true}));
      return api(`/clients/get-accounts/${body.id}`, {
        method: "GET",
      })
        .then((tableData) => {
          tableData && dispatch(setTableData({isLoad: false}));
          let newData = tableData && tableData.map((item) => {
            return {
              ...item,
              value: `${item.advName}_${item.adAccountId}`,
              id: item.id,
              serviceType: item.serviceType || 1,
              advName: item.advName,
              // label: item.advName + " " + item.adAccountId,
              label: <div className={"d-flex justify-content-between"}>
                <div>{ServiceTypesImg(+item.serviceType)}</div>
                <div>{item.advName}</div>
                <div>{item.adAccountId}</div>
              </div>,
              balance: item.balance,
              commission: item.commission,
              totalSum: item.fullSum,
            }
          });
          return tableData && tableData.entries && dispatch(setTableData({[type]: newData}));
        });

    case "payment_info":
      dispatch(setTableData({isLoad: true}));
      return api("/payline/info/requires-editing", {
        method: "POST",
        data: {},
      })
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          if (tableData) {
            return dispatch(setTableData({[type]: tableData.usersData}));
          }

        });

    case "payers":
      dispatch(setTableData({isLoad: true}));
      return api("/payline/all")
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(setTableData({[type]: tableData || []}));
        });

    case "generalCommission":
      dispatch(setTableData({isLoad: true}));
      return api("/commissions/get-all-general", {method: "GET"})
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          const newData = tableData?.map((item) => {
            return {
              ...item,
              countryField: countryFormatter(item.countryId)
            }
          })
          return dispatch(setTableData({[type]: newData}));
        });

    case "individualCommissions":
      dispatch(setTableData({isLoad: true}));
      return api("/commissions/get-all-individual")
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(setTableData({[type]: tableData}));
        });

    case "individualBonusesData":
      return api("/bonuses/individual")
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(setTableData({[type]: tableData.bonusesData}));
        });
    case typePages.generalBonuses:
      dispatch(setTableData({isLoad: true}));
      return api(apiUrls.generalBonuses, {method: "GET"})
        .then(response => {
          dispatch(setTableData({isLoad: false}));
          const newData = response?.map((item) => {
            return {
              ...item,
              countryField: countryFormatter(item.countryId)
            }
          })
          return dispatch(setTableData({[type]: newData}));
        });

    case typePages.individualBonuses:
      dispatch(setTableData({isLoad: true}));
      return api(apiUrls.individualBonuses, {method: "GET"})
        .then(response => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(setTableData({[type]: response}));
        });

    case "addAccountsGoogle":
      return api("/attach/adwords-accounts")
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(
            setTableData({[type]: tableData.adwordsAccountsToAttachData})
          );
        });

    case "addAccountsYandex":
      return api("/attach/direct-accounts")
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(
            setTableData({[type]: tableData.directAccountsToAttachData})
          );
        });
    case "requestFB":
      return api("/facebook/registration/get-accounts", {method: "GET"})
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          if (tableData) {
            let newData = tableData.map(data => {
              return {
                ...data,
                commentText: data?.comment?.message || "",
                statusText: statusFormatter(data.status),
                lastEditDate: FormatterDateFromTo(data.lastEditDate, YYYYMMDDFullFormat, DDMMYYYYFullFormat),
                creationDate: FormatterDateFromTo(data.creationDate, YYYYMMDDFullFormat, DDMMYYYYFullFormat),
              }
            })
            return dispatch(
              setTableData({[type]: newData || []})
            );
          }
        });

    case "requestYandex":
      return api("/yandex/registration/all", {method: "GET"})
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          if (tableData) {
            let newData = tableData.map(data => {
              return {
                ...data,
                commentText: data?.comment?.message || "",
                statusText: statusFormatter(data.status),
                updatingDate: FormatterDateFromTo(data.updatingDate, YYYYMMDDFullFormat, DDMMYYYYFullFormat),
                creationDate: FormatterDateFromTo(data.creationDate, YYYYMMDDFullFormat, DDMMYYYYFullFormat),
              }
            })
            return dispatch(
              setTableData({[type]: newData || []})
            );
          }
        });
    case "requestTikTok":
      return api("/tik-tok/registration/all", {method: "GET"})
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          if (tableData.entries) {
            let newData = tableData.map(data => {
              return {
                ...data,
                //2023-07-06T06:45:19
                // "2023-07-06T08:04:36"
                creationDate: moment(data.creationDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss") || "",
                updatingDate: moment(data.updatingDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss") || "",
                commentText: data?.comment?.message || "",
                statusText: data?.status ? statusFormatter(data.status) : "",
              }
            })
            return dispatch(
              setTableData({[type]: newData || []})
            );
          }
        });

    case "users":
      dispatch(setTableData({isLoad: true}));
      return api("/users/all", {
        method: "GET",
      })
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(setTableData({[type]: tableData}));
        });

    case "freeMcc":
    case "occupiedMcc":
      dispatch(setTableData({isLoad: true}));
      return api("/google/mcc/all", {
        method: "POST",
      })
        .then((tableData) => {
          let counter = tableData && tableData.freeCounter;
          let freeMcc = [];
          let occupiedMcc = [];

          tableData && tableData.mccList.map(item => {
            if (item.isFree) {
              return freeMcc.push(item);
            } else {
              return occupiedMcc.push(item);
            }
          });
          dispatch(setTableData({freeMcc: freeMcc}));
          dispatch(setTableData({occupiedMcc: occupiedMcc}));
          dispatch(setTableData({counterFreeMcc: counter}));
          dispatch(setTableData({counterOccupiedMcc: occupiedMcc.length}));
          return dispatch(setTableData({isLoad: false}));
        });
    case "sidebarInfo":
      dispatch(setTableData({isLoad: true}));
      return api("/sidebar/informants", {method: "GET"})
        .then((res => {
            dispatch(setTableData({isLoad: false}));
            return dispatch(setTableData({[type]: res}));
          })
        )
    case "manual_replenish":
      dispatch(setTableData({isLoad: true}));
      //from=2023-01-12T12:12:12&to=2023-10-19T12:12:12
      return api(`/manual_replenishment/completed-records?from=${body.from}T00:00:00&to=${body.to}T23:59:59`, {method: "GET"})
        .then((res => {
            let newResult = Array.isArray(res) ? res?.map((replenish) => {
              return {
                ...replenish,
                replenishmentRecordDate: FormatterDateFromTo(replenish.replenishmentRecordDate, YYYYMMDDFullFormat, DDMMYYYYFullFormat),

                replenishmentDate: FormatterDateFromTo(replenish.replenishmentDate, YYYYMMDDFullFormat, DDMMYYYYFullFormat),
                type: FormatterTypeReplenishment(replenish.type) || "",
                creationOnlyDate: FormatterDateFromTo(replenish.creationDate, "YYYY-MM-DD HH:mm:ss", DDMMYYYYFormat),
                updatingOnlyDate: FormatterDateFromTo(replenish.updatingDate, "YYYY-MM-DD HH:mm:ss", DDMMYYYYFormat),
                creationTime: FormatterDateFromTo(replenish.creationDate, "YYYY-MM-DD HH:mm:ss", "HH:mm"),
                updatingTime: FormatterDateFromTo(replenish.updatingDate, "YYYY-MM-DD HH:mm:ss", "HH:mm"),
                replenishmentOnlyDate: FormatterDateFromTo(replenish.replenishmentDate, YYYYMMDDFullFormat, DDMMYYYYFormat),
                replenishmentOnlyTime: FormatterDateFromTo(replenish.replenishmentDate, YYYYMMDDFullFormat, "HH:mm"),
                adAccountId: replenish?.accountInfo?.adAccountId || "",
                advName: replenish?.accountInfo?.advName || "",
                serviceType: replenish?.accountInfo?.serviceType ? ServiceText(replenish?.accountInfo?.serviceType) : "",
                // replenishmentRecordDate: moment(replenish.replenishmentRecordDate).format("DD.MM.YYYY "),
                // replenishmentDate: replenish.replenishmentDate && moment(replenish.replenishmentDate).format("DD.MM.YYYY  |  HH:mm"),
              }
            }) : []
            return dispatch(setTableData({[type]: newResult || []}));
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "manual_deduct":
      dispatch(setTableData({isLoad: true}));
      const dateFromDeduct = moment(body.from, "YYYY-MM-DD").format("YYYY-MM-DD");
      const dateToDeduct = moment(body.to, "YYYY-MM-DD").format("YYYY-MM-DD");
      const manualDeductURL = `/manual_deduct/all?from=${dateFromDeduct}T00:00:00&to=${dateToDeduct}T23:59:59`;
      return api(manualDeductURL, {method: "GET"})
        .then((res => {
            let newResult = Array.isArray(res) ? res?.map((item) => {
              return {
                ...item,
                operationType: item.operationType === "manualDeduct" ? i18next.t("manualDeduct") : "",
                clientType: item.clientType === "centerClient" ? i18next.t("CC") : i18next.t("C"),
                date: moment(item.date).format("DD.MM.YYYY | HH:mm"),
              }
            }) : [];
            return dispatch(setTableData({[type]: newResult}));
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));


    case "bonusesTerms":
      dispatch(setTableData({isLoad: true}));
      return api("/reports/bonus-terms", {
        method: "GET",
      })
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          let newData = tableData.entries && tableData.map(item => {
            let rangesAmount = tableData.entries[tableData.entries.length - 1].ranges.length;
            let ranges = {};
            for (let i = 0; i < rangesAmount; i++) {
              ranges[`amountFrom_${i}`] = item.ranges[i] ? item.ranges[i].amountFrom : 0;
              ranges[`amountTo_${i}`] = item.ranges[i] ? item.ranges[i].amountTo : 0;
              ranges[`commissionType_${i}`] = item.ranges[i] ? item.ranges[i].commissionType === "PERCENT" ? "%" : "фикс. знач." : 0;
              ranges[`value_${i}`] = item.ranges[i] ? item.ranges[i].value : 0;
            }
            return {
              ...item,
              residentRb: item.residentRb === "yes" ? i18next.t("yes") : i18next.t("no"),
              bonusCondition: item.bonusCondition === "INDIVIDUAL" ? i18next.t("individualShort") : i18next.t("general"),
              serviceTypeId: item.serviceTypeId === 3 ? "Facebook" : item.serviceTypeId === 1 ? "Google" : "unknown",
              replenishType: item.replenishType === "AUTO" ? i18next.t("autoManagement") : i18next.t("manualManagement"),
              ...ranges
            }
          });
          return dispatch(setTableData({[type]: newData}));
        });


    case "BonusesAccrued":
      dispatch(setTableData({isLoad: true}));
      return api("/reports/bonus-accrued", {
        method: "GET",
      })
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          let newData = tableData.entries && tableData.map(item => {
            return {
              ...item,
              dateFormat: item.date.split("-").reverse().join("."),
              replenishType: item.replenishType === "AUTO" ? i18next.t("autoManagement") : i18next.t("manualManagement"),
              serviceTypeId: item.serviceTypeId === 3 ? "Facebook" : item.serviceTypeId === 1 ? "Google" : "unknown",
              residentRb: item.residentRb === "yes" ? i18next.t("yes") : i18next.t("no"),
              bonusType: item.bonusType === "PERCENT" ? "%" : "фикс. знач.",
            }
          })
          return dispatch(setTableData({[type]: newData}));
        });

    case "countries":
      dispatch(setTableData({isLoad: true}));
      const lang = i18n.language;
      console.log("lang", lang);
      // ADVERTON-2938 delete ru language from AP
      return api(`/country/en/all`, {method: "GET"})
        .then((res => {
            dispatch(setTableData({[type]: res}));
            return res
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "showCommissionModal":
    case "showBonusesModal":
      return dispatch(setTableData({[type]: body}));
    case "clientCardHead":
      dispatch(setTableData({cardInfoLoad: true}));
      return (api(`/clients/card/info/${body.id}`, {
        method: "GET"
      })).then(res => {
        return dispatch(setTableData({[type]: {...res, id: body.id} || {}}))
      })
        .finally(() => dispatch(setTableData({cardInfoLoad: false}, {payload: false})))

    case "expenses":
      dispatch(setTableData({isLoad: true}));
      // url http://localhost:3021/clients/card/expense/531
      // return (api("/country/all", {
      return (api(`/clients/card/expense/${body.id}`, {
        method: "GET"
      })).then(res => {
        const newData = [...res?.expenses].map(item => {
          const newData = {
            avgExpense: ReplaceDotToComma(item.avgExpense),
            avgExpensePercentage: ReplaceDotToComma(item.avgExpensePercentage),
            expense1: ReplaceDotToComma(item.expense1),
            expense2: ReplaceDotToComma(item.expense2),
            expense3: ReplaceDotToComma(item.expense3),
            expenseExpectations: ReplaceDotToComma(item.expenseExpectations),
            nowExpense: ReplaceDotToComma(item.nowExpense),
            nowExpensePercentage: ReplaceDotToComma(item.nowExpensePercentage),
          }
          if (item.serviceType) {
            return {
              ...item,
              ...newData,

              serviceTypeName: ServiceText(item.serviceType)
            }
          } else {
            return {
              ...item,
              ...newData,
              serviceTypeName: i18next.t("total")
            }
          }
        })
        return dispatch(setTableData({[type]: newData || []}))
      }).finally(() => dispatch(setTableData({isLoad: false})))

    case "Account_expenses_period":
      dispatch(setTableData({Account_expenses_period_load: true}));
      const dateFrom = moment(body.from, "DD.MM.YYYY").format(YYYYMMDDFullFormat);
      const dateTo = moment(body.to, "DD.MM.YYYY").format(YYYYMMDDFullFormat);
      // console.log("url", body, `/clients/card/expense/month/${body.clientId}?dateFrom=${dateFrom}&dateTo=${dateTo}`);

      return api(`/clients/card/expense/month/${body.clientId}?dateFrom=${dateFrom}&dateTo=${dateTo}`, {
        method: "GET",
      })
        .then((res) => {
          const newData = [...res?.clientCardMonthExpenseRows].map(item => {
            const newData = {
              FACEBOOK: ReplaceDotToComma(item.FACEBOOK),
              GOOGLE: ReplaceDotToComma(item.GOOGLE),
              TIKTOK: item.TIK_TOK !== null ? ReplaceDotToComma(item.TIK_TOK) : "-",
            }
            if (item.month) {
              return {
                ...newData,

                month: moment(item.month, "MM.YYYY").format("MMMM YYYY").toUpperCase()
              }
            } else {
              return {...newData, month: i18next.t("total")}
            }
          })
          return dispatch(setTableData({[type]: newData}))
        }).finally(() => dispatch(setTableData({Account_expenses_period_load: false})));

    case "finDocs":
      dispatch(setTableData({isLoad: true}));
      const urlFinDocs = `/clients/card/documents/get/all/${body.id}?dateFrom=${body.dateFrom}T00:00:00&dateTo=${body.dateTo}T23:59:59`;
      return (api(urlFinDocs, {
        method: "GET"
      })).then(res => {

        // const MockData=[
        //   {
        //     "documentType": "BILL",
        //     "documentNumber": "B20242076",
        //     "date": "2024-02-23T06:00:00",
        //     "base": "BILL cb9795ec-d211-11ee-9049-0050560100ac",
        //     "sum": "1344000.0",
        //     "currency": "KZT",
        //     "guid": "7bb3a690-d226-11ee-9049-0050560100ac",
        //     "signed": false
        //   },
        //   {
        //     "documentType": "PAYMENT",
        //     "documentNumber": "БК000000067",
        //     "date": "2024-02-23T03:00:00",
        //     "base": "",
        //     "sum": "1344000.0",
        //     "currency": "KZT",
        //     "guid": "cb9795ec-d211-11ee-9049-0050560100ac",
        //     "signed": false
        //   }
        // ];

        // const newData = [...MockData].map(item => {
        const newData = [...res].map(item => {
          return {
            ...item,
            date: moment(item.date, YYYYMMDDFullFormat).format(DDMMYYYYFullFormat), // 08.04.2023 20:35:34
            documentTypeText: docTypeFormatter(item.documentType),
            sum: +item.sum
          }
        });
        return dispatch(setTableData({[type]: newData || []}))
      }).finally(() => dispatch(setTableData({isLoad: false})))

    case "moneyMovement":
      dispatch(setTableData({isLoad: true}));

      let url = `/clients/card/transaction/${body.id}?dateFrom=${body.dateFrom}T00:00:00&dateTo=${body.dateTo}T23:59:59`;
      return api(url, {
        method: "GET"
      }).then(res => {

        let newData = res && [...res?.transactionHistories]?.map(item => {
          const fromText1 = item.from.name;
          const fromText2 = DataFormatter(item.operationType !== "transfer" ? item.fromType : "adAccount");
          const fromText3 = item.from.id ? "ID " + item.from.id : "";
          const fromText = `${fromText1}  ${fromText2} ${fromText3}`;
          const fromText4 = (item.operationType === "fine" || item.operationType === "googleTax") && item.adAccountId ?
            `(ID ${item.adAccountId})` : "";

          const toText1 = item.to.name;
          const toText2 = DataFormatter(item.operationType !== "transfer" ? item.toType : "adAccount");
          const toText3 = item.to.id ? "ID " + item.to.id : "";
          const toText = `${toText1} ${toText2} ${toText3}`;

          return {
            ...item,
            operationTypeText: DataFormatter(item.operationType),
            paymentSum: +item.paymentSum,
            paymentSumText: item?.paymentSum,
            accountSumPayment: +item.accountSumPayment,
            accountSumPaymentText: item?.accountSumPayment,
            fromText: fromText,
            fromText1,
            fromText2,
            fromText3,
            toText: toText,
            toText1,
            toText2,
            toText3,
            fromText4,
            dateText: moment(item.date, YYYYMMDDFullFormat).format(DDMMYYYYFullFormat)
          }

        }) || []

        dispatch(setTableData({[type]: newData}));
        return res
      }).finally(() => dispatch(setTableData({isLoad: false})));

    case "googleTax":
      dispatch(setTableData({isLoad: true}));

      let urlGetTax = `/account-taxes/?from=${body.dateFrom}T00:00:00&to=${body.dateTo}T23:59:59`;
      return api(urlGetTax, {
        method: "GET"
      }).then(res => {
        const newData = Array.isArray(res) ? res?.map(item => {
          return {
            ...item,
            period: FormatterDateFromTo(item.period, "MM.DD.YYYY", "DD.MM.YYYY"),
            updatedDate: FormatterDateFromTo(item.updatedDate, "MM.DD.YYYY HH:mm:ss", "DD.MM.YYYY HH:mm:ss"),
            serviceTypeText: ServiceText(item.serviceType),
            status: item.fullPaid ? i18next.t("payed") : i18next.t("notPayed"),
          }
        }) : [];
        dispatch(setTableData({[type]: newData}));
        return newData;
      }).finally(() => dispatch(setTableData({isLoad: false})));

    case "googleTaxPreview":
      dispatch(setTableData({isLoad: true}));

      let urlGetTaxPreview = `/account-taxes/preview?date=${body.date}T00:00:00`;
      return api(urlGetTaxPreview, {
        method: "GET"
      }).then(res => {
        // const MOckData = [
        //   {
        //     "period": "02.01.2024",
        //     "paylineName": "NMNK BALTIC",
        //     "serviceName": "GOOGLE",
        //     "advName": "ADV-XXX-1-app-8",
        //     "adAccountId": "8669316149",
        //     "date": "03.15.2024",
        //     "time":"08:02:36",
        //     "sum": "0,0",
        //     "currencyName": "USD"
        //   },
        //   {
        //     "period": "02.01.2024",
        //     "paylineName": "NEVA SHOW ANIMASYON TURIZM TICARET VE SANAYI TLS",
        //     "serviceName": "GOOGLE",
        //     "advName": "ADV-XXX-1-app-9",
        //     "adAccountId": "5394807348",
        //     "date": "03.15.2024",
        //     "time":"08:02:36",
        //     "sum": "0.18",
        //     "currencyName": "USD"
        //   },
        //   {
        //     "period": "02.01.2024",
        //     "paylineName": "ANCHOR MEDIA GROUP SP Z O O",
        //     "serviceName": "GOOGLE",
        //     "advName": "ADV-XXX-1-tui",
        //     "adAccountId": "7086328880",
        //     "date": "03.15.2024",
        //     "time":"08:02:36",
        //     "sum": "0.62",
        //     "currencyName": "USD"
        //   },
        //   {
        //     "period": "02.01.2024",
        //     "paylineName": "Adconcept OU",
        //     "serviceName": "GOOGLE",
        //     "advName": "ICNT-M-S-ga 552",
        //     "adAccountId": "5253704103",
        //     "date": "03.15.2024",
        //     "time":"08:02:36",
        //     "sum": "0.0",
        //     "currencyName": "USD"
        //   },
        // ];

        dispatch(setTableData({[type]: res}));
        // dispatch(setTableData({[type]: MOckData}));
        return res;
      }).finally(() => dispatch(setTableData({isLoad: false})));

    case "manualPenalty":
      dispatch(setTableData({isLoad: true}));
      // from=2023-10-01T00:00:00&to=2023-10-04T23:59:59
      let manualPenaltyURL = `/fines/policy-fines?from=${body.dateFrom}T00:00:00&to=${body.dateTo}T23:59:59`;

      return api(manualPenaltyURL, {method: "GET"})
        .then((res => {
            let newData = Array.isArray(res) ? res?.map(item => {
              return {
                ...item,
                type: i18next.t("fineModalTitle", {service: ServiceText(item.serviceType)}),
                serviceText: ServiceText(item.serviceType),
                isPaidStatus: item.isFullPaid ? i18next.t("payedFine") : i18next.t("notPayedFine"),
              }
            }) : [];
            dispatch(setTableData({[type]: newData}));
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "managementTag":
      dispatch(setTableData({managementTagIsLoad: true}));

      return api("/tags/management/list", {method: "GET"})
        .then((res => {
            dispatch(setTableData({[type]: res || []}));
            return res
          })
        ).finally(() => dispatch(setTableData({managementTagIsLoad: false})));

    case "clientTag":
      dispatch(setTableData({clientTagIsLoad: true}));

      return api("/tags/list", {method: "GET"})
        .then((res => {
            dispatch(setTableData({[type]: res || []}));
            return res
          })
        ).finally(() => dispatch(setTableData({clientTagIsLoad: false})));

    case "teamsList":
      dispatch(setTableData({isLoad: true}));

      return api("/teams/list", {method: "GET"})
        .then((res => {
            let newData = [...res?.teams]?.map(item => {
              let teamLeadsText = item.teamLeads?.map(item => {
                return item.fio
              })
                ?.join(item?.teamLeads?.length > 1 ? ", " : " ");

              let tagsList = item?.projectsAccess?.map(item => item.tagName || item.tagId)
                ?.join(item?.projectsAccess?.length > 1 ? ", " : " ")

              return {
                ...item,
                teamLeadsText,
                tagsList,

              }
            })
            dispatch(setTableData({[type]: newData || []}));
            return res
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "toServiceAccess":
      dispatch(setTableData({isLoad: true}))
      return api(`/cabinet-services/to-service-access/${body.id}`, {method: "GET"})
        .then((res => {
            dispatch(setTableData({[type]: res?.serviceTypePropertyResponses}));
            return res
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "serviceUtm":
      dispatch(setTableData({isLoad: true}))
      ///utm-services/all/{cabinetId}
      return api(`/utm-services/all/${body.id}`, {method: "GET"})
        .then((res => {
            dispatch(setTableData({[type]: res}));
            return res
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "accountDeposits":
      dispatch(setTableData({isLoad: true}));

      return api(`/reports/account-deposits?from=${body.from}&to=${body.to}`, {method: "GET"})
        .then((res => {
            const newDataActive = Array.isArray(res) ? res?.map(item => {
              return {
                ...item,
                date: FormatterDateFromTo(item.date, YYYYMMDDFormat, DDMMYYYYFormat),
              }
            }) : [];
            dispatch(setTableData({[type]: newDataActive}));
            return newDataActive
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "finalAmount":
      dispatch(setTableData({isLoad: true}));

      return api(`/reports/final-amount?from=${body.from}&to=${body.to}`, {method: "GET"})
        .then((res => {
            const newDataActive = Array.isArray(res) ? res?.map(item => {
              return {
                ...item,
                date: FormatterDateFromTo(item.date, YYYYMMDDFormat, DDMMYYYYFormat),
              }
            }) : [];
            dispatch(setTableData({[type]: newDataActive}));
            return newDataActive
          })
        ).finally(() => dispatch(setTableData({isLoad: false})));

    case "providersList":
      dispatch(setTableData({isLoadProviders: true}));
      const urlProvidersList = body?.serviceType ? `/provider/get?serviceType=${body.serviceType}` : `/provider/get`;
      return api(urlProvidersList, {method: "GET"})
        .then((res) => {
          let newData = res?.providers?.map(item => {
            return {
              ...item,
              value: item.id,
              label: item.shortName,
              serviceTypeText: ServiceText(item.serviceType),
            }
          })
          dispatch(setTableData({[type]: newData}));
          return newData
        })
        .finally(() => dispatch(setTableData({isLoadProviders: false})));

    case "finApplicationsList":
      dispatch(setTableData({isLoad: true}));

      // application/all?from=2019-05-31T23:59:59&to=2024-05-31T23:59:59
      let finAppUrl = `/application/all?from=${body.from}T00:00:00&to=${body.to}T23:59:59`;

      return api(finAppUrl, {method: "GET"})
        .then((res) => {
          let newData = Array.isArray(res) ? res?.map(item => {
            return {
              ...item,

              applicationTypeText: FinApplicationTypeFormatter(item.applicationType),

              statusText: FinApplicationsStatusFormatter(item.status),

              fromAccountId: item?.fromAccount?.adAccountId,
              fromPayline: item?.fromCabinet?.payLineName,

              toAccountId: item?.toAccount?.adAccountId,
              toPayline: item?.toCabinet?.payLineName,

              currency: item?.currencyTo?.alphabeticCode,

              applicationCreationDate: moment(item.applicationCreationDate, "YYYY-MM-DDTHH:mm:ss").format("DD.MM.YYYY | HH:mm:ss"),

              commentMessage: item?.comment?.message,
            }
          }) : [];
          dispatch(setTableData({[type]: newData}));
          return newData
        })
        .finally(() => dispatch(setTableData({isLoad: false})))

    default:
      dispatch(setTableData({isLoad: true}));
      return api("/users/all", {
        method: "GET",
      })
        .then((tableData) => {
          dispatch(setTableData({isLoad: false}));
          return dispatch(setTableData({[type]: tableData.items}));
        });
  }
};

export const getTableData = (state) => state.tableData;

export default tableDataSlice.reducer;
