import * as Yup from "yup";
import {isValidPhoneNumber} from "react-phone-number-input";
import {payerTypes} from "./payerTypes";
import {validationSum} from "../utils/sumValidation";
import i18next from "i18next";


export const validationSchemaForGrid = (t) => Yup.array()
  .of(
    Yup.object().shape({
      budgetFrom: Yup.string()
        .test("testBudgetFrom", t("validation.enterNumber"), (val) => {
          const regex = /^-?\d*(\.|,)?\d*$/;
          let valueDot;
          if (val && regex.test(val.toString())) {
            valueDot = val.replace(",", ".");
          }
          if (val === undefined) {
            return true
          }
          return !isNaN(valueDot)
        })
        // .typeError(i18next.t("validation.enterNumber"))
        .required(t("validation.required")),
      amount: Yup.string()
        .test("testAmount", t("validation.enterNumber"), (val) => {
          const regex = /^-?\d*(\.|,)?\d*$/;
          let valueDot;
          if (val && regex.test(val.toString())) {
            valueDot = val.replace(",", ".");
          }
          if (val === undefined) {
            return true
          }
          return !isNaN(valueDot)
        })
        .required(t("validation.required")),
      currency: Yup.object().required(t("validation.required")), // these constraints take precedence
    })
  )
  .required(t("validation.allFieldsRequired"));


export const validationSchemaAddGeneralCommission = (t) => {
  return (
    Yup.object().shape({
      commission: Yup.number()
        .positive(t("validation.positiveNumber"))
        .typeError(t("validation.enterNumber"))
        .required(t("validation.required"))
        .max(100, t("validation.notMore", {number: 100}))
        .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
          return val && validationSum(val)
        }),
      serviceId: Yup.object()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
      countryId: Yup.object()
        .typeError(t("validation.required"))
        .required(t("validation.required")),
    }))
};

export const validationSchemaAddIndividualCommission = (t) => Yup.object().shape({
  commission: Yup.number()
    .positive(t("validation.positiveNumber"))
    .typeError(t("validation.enterNumber"))
    .required(t("validation.required"))
    .max(100, t("validation.notMore", {number: 100}))
    .test("testCommission", t("validation.twoSymbolAfterComma"), function (val) {
      return val && validationSum(val)
    }),
  cabinetId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});


export const validationSchemaAddGeneralBonuses = (t) => Yup.object().shape({
  commissions: validationSchemaForGrid(t),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  countryId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});

export const validationSchemaAddIndividualBonuses = (t) => Yup.object().shape({
  commissions: validationSchemaForGrid(t),
  cabinetId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
  serviceId: Yup.object()
    .typeError(t("validation.required"))
    .required(t("validation.required")),
});

export const emailValidation = Yup.string()
  .max(250, i18next.t("validation.notMore", {number: 250}))
  .email(i18next.t("validation.incorrectEmailt"));

export const passwordValidation = (t) => Yup.string()
  .min(8, t("validation.noLess", {number: "8"}))
  .matches(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/, t("validation.validPassword"));

export const phoneValid = Yup.string()
  .test("testPhone", i18next.t("validation.incorrectPhone"), function (value) {
      if (value) {
        return isValidPhoneNumber(value);
      } else {
        return true
      }
    }
  ).nullable();

export const paylineKZ = (t)=>Yup.object({
  legalUnp: Yup.string()
    .matches(/^[0-9]*$/, t("validation.enterNumber"))
    .max(12, t("validation.notMore", {number: 12}))
    .min(12, t("validation.noLess", {number: 12}))
    .required(t("validation.required")),
  legalForm: Yup.string().required(t("validation.required")),
  legalName: Yup.string().required(t("validation.required")),
  certificateNumber: Yup.string()
    .matches(/^[0-9]*$/, t("validation.enterNumber"))
    .max(10, t("validation.notMore", {number: 10}))
    .nullable(),
  legalIndex: Yup.string()
    .matches(/^[0-9]*$/, t("validation.enterNumber"))
    .min(7, t("validation.noLess", {number: 7}))
    .max(7, t("validation.notMore", {number: 7})),
  legalAddress: Yup.string()
    .required(t("validation.required")),
  legalPaymentAccount: Yup.string()
    .test("testLength", t("validation.required"), function (value) {
      const {payerType} = this.parent;
      if (payerType !== payerTypes.LEGAL) {
        return true
      }
      return value && value.replace(/_/ig, '').replace(/\s/ig, '').length !== 0
    })
    .test("testPaymentAccount", "Введите 20 символов", function (value) {
      const {payerType} = this.parent;
      if (payerType !== payerTypes.LEGAL) {
        return true
      }
      return value && value.replace(/_/ig, '').replace(/\s/ig, '').length === 20
    }),
  legalBik: Yup.string()
    .test("testBic", t("validation.required"), function (value) {
      const {payerType} = this.parent;
      if (payerType !== payerTypes.LEGAL) {
        return true
      }
      return value && value !== ""
    }),
  bankAddress: Yup.string()
    .test("testBankName", t("validation.required"), function (value) {
      const {payerType} = this.parent;
      if (payerType !== payerTypes.LEGAL) {
        return true
      }
      return value && value !== ""
    }),

  contactName: Yup.string()
    .required(t("validation.required"))
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect")),
  contactFamilyName: Yup.string()
    .required(t("validation.required"))
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect")),
  contactPatronymic: Yup.string()
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect")),

  contactEmail: Yup.string()
    .email(t("validation.incorrectEmailt"))
    .required("Введите email"),

  contactMobile: Yup.array().of(
    Yup.object().shape({
      phone: phoneValid.required(t("validation.required"))
    })
  ),
  contactMobileAccounting: Yup.array().of(
    Yup.object().shape({
      phone: phoneValid
    })
  ),
  contactFamilyNameAccounting: Yup.string()
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect"))
    .when(
      "isEquivalent",
      (isEquivalent, schema) => {
        return isEquivalent ? schema : schema.required(t("validation.required"))
      }),
  contactNameAccounting: Yup.string()
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect"))
    .when(
      "isEquivalent",
      (isEquivalent, schema) => {
        return isEquivalent ? schema : schema.required(t("validation.required"))
      }),
  contactEmailAccounting: Yup.string()
    .email(t("validation.incorrectEmailt"))
    .when(
      "isEquivalent",
      (isEquivalent, schema) => {
        return isEquivalent ? schema : schema.required(t("validation.required"))
      }),
});

export const paylineManual = (t)=> Yup.object({
  legalUnp: Yup.string()
    .matches(/^[A-Za-z0-9]*$/, t("validation.enterNumber"))
    .max(18, t("validation.notMore", {number: 18}))
    .min(2, t("validation.noLess", {number: 2}))
    .required(t("validation.required")),

  legalForm: Yup.string().required(t("validation.required")),
  legalName: Yup.string().required(t("validation.required")),
  legalPaymentAccount: Yup.string()
    .matches(/^[A-Za-z0-9]*$/, t("validation.onlyLatinLetterAndNumber"))
    .max(50, t("validation.notMore", {number: 50})),

  legalBik: Yup.string()
    .max(50, t("validation.notMore", {number: 50}))
    .matches(/^[A-Za-z0-9]*$/, t("validation.onlyLatinLetterAndNumber")),

  contactName: Yup.string()
    .required(t("validation.required"))
    .matches(/^[a-zA-zА-Яа-я0-9_\-,\s]*$/i, t("validation.enterNotCorrect")),
  contactFamilyName: Yup.string()
    .required(t("validation.required"))
    .matches(/^[a-zA-zА-Яа-я0-9_\-,\s]*$/i, t("validation.enterNotCorrect")),
  contactPatronymic: Yup.string()
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect")),

  contactEmail: Yup.string()
    .email(t("validation.incorrectEmailt")),

  contactMobile: Yup.array().of(
    Yup.object().shape({
      phone: phoneValid
    }).nullable()
  ),
  contactFamilyNameAccounting: Yup.string()
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect"))
    .when(
      "isEquivalent",
      (isEquivalent, schema) => {
        return isEquivalent ? schema : schema.required(t("validation.required"))
      }),
  contactNameAccounting: Yup.string()
    .when(
      "isEquivalent",
      (isEquivalent, schema) => {
        return isEquivalent ? schema : schema.required(t("validation.required"))
      })
    .matches(/^[a-zA-zА-Яа-я\-,\s]*$/i, t("validation.enterNotCorrect")),
  contactEmailAccounting: Yup.string()
    .email(t("validation.incorrectEmailt"))
    .when(
      "isEquivalent",
      (isEquivalent, schema) => {
        return isEquivalent ? schema : schema.required(t("validation.required"))
      }),
})
