import React, {useEffect} from "react";
import {useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {useDispatch, useSelector} from "react-redux";
import generateExcel from "zipcelx";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import BTable from 'react-bootstrap/Table';

import {getTableData, loadTableData} from "reduxStore/reducers/tableData";
import {Load} from "components/load/Load";
import Pagination from "../common/Paginations";
import MultiSelectColumns from "./MultiSelectColumns";

import Search from "assets/images/search.svg";
import ResetSettings from "assets/images/resetSettings.svg";

// svgr

export function getHeader(column) {
  // console.log("column", column)
  if (column.totalVisibleHeaderCount === 1) {
    return [
      {
        value: column.Header,
        type: "string"
      }
    ];
  } else {
    const span = [...Array(column.totalVisibleHeaderCount - 1)].map(() => ({
      value: "",
      type: "string"
    }));
    return [
      {
        value: column.Header,
        type: "string"
      },
      ...span
    ];
  }
}


export function getExcelOnly(exportName, rows, headers) {
  const config = {
    filename: `${exportName}`,
    sheet: {
      data: []
    }
  };
  console.log("rows", rows)
  const dataSet = config.sheet.data;
  // review with one level nested config
  // HEADERS

  // console.log("rows", rows)
  // FILTERED ROWS
  // console.log("rows.length", rows.length, rows)
  if (rows.length > 0) {
    const data = [headers].concat(rows);
    // console.log("data", data)
    dataSet.push(...data);
  } else {
    dataSet.push(headers);
    dataSet.push([
      {
        value: i18next.t("noData"),
        type: "string"
      }
    ]);
  }
  // console.log("config", config)
  return generateExcel(config);
}


export function getExcel(exportName, headerGroups, rows, prepareRow, excludedColumnsId = []) {
  const config = {
    filename: `${exportName}`,
    sheet: {
      data: []
    }
  };

  const dataSet = config.sheet.data;
  // review with one level nested config
  // HEADERS
  headerGroups.forEach(headerGroup => {
    const headerRow = [];
    if (headerGroup.headers) {
      headerGroup.headers.forEach(column => {
        // console.log("column", column)

        if (excludedColumnsId.includes(column.id)) return
        headerRow.push(...getHeader(column));
      });
    }

    dataSet.push(headerRow);
  });

  // FILTERED ROWS
  if (rows.length > 0) {
    rows.forEach((row) => {
      // console.log("row", row)
      prepareRow(row)
      const dataRow = [];
      [...row.cells].forEach((row) => {
        if (excludedColumnsId.includes(row.column.id)) return
        return dataRow.push({
          value: row.value,
          type: typeof row.value === "number" ? "number" : "string"
        })
      })
      dataSet.push(dataRow);
    });
  } else {
    dataSet.push([
      {
        value: i18next.t("noData"),
        type: "string"
      }
    ]);
  }
  // console.log("config", config)
  return generateExcel(config);
}

const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

// Define a default UI for filtering
export function GlobalFilter({
                               // preGlobalFilteredRows,
                               gotoPage,
                               globalFilter,
                               setGlobalFilter,
                               width = 360,
                               className = "pr-3"
                             }) {
  // const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter);
  const {t} = useTranslation();
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className={`position-relative ${className}`}>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
          gotoPage(0);
        }}
        className={"form-control"}
        placeholder={t("search")}
        style={{
          width: `${width}px`,
          paddingLeft: "40px"
        }}
      />
      <span className={"position-absolute"} style={{
        left: "12px",
        top: "8px"
      }}>
              <img src={Search} alt={"search"}/>
            </span>
    </div>
  )
}

// Define a default UI for filtering
export function DefaultColumnFilter({
                                      column: {filterValue, preFilteredRows, setFilter},
                                    }) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

export const filterTypes = {

  // Or, override the default text filter to use
  // "startWith"
  text: (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue)?.toLowerCase())
        : true
    })
  },
  multiSelect: (rows, id, filterValues) => {
    // console.log("showResetFilterBtn", id, filterValues, rows)
    if (filterValues.length === 0) return rows;
    return rows.filter(r => filterValues.includes(r.values[id]));
  },
  dateRange: (rows, id, filterValues) => {
    if (filterValues.length === 0) return rows;
    return rows.filter(r => filterValues.includes(r.values[id]));
  },
};

export const TRHead = ({headerGroup}) => {
  return (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map(column => {
        return (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}
              key={column.id}
              style={{
                minWidth: column.widthCol ? column.widthCol : "auto",
                verticalAlign: "middle",
              }}
          >
            <div
              className={`d-flex align-items-center ${column.center ? "justify-content-center text-center" : ""}`}>{typeof column.Header === "string" ? column.render('Header') : column.render(column.Header())}
              {/* Render the columns filter UI */}

              <>
                {!column.disableSortBy ? (
                  <div
                    className={`sorted-icon ml-2 ${column.isSorted ? column.isSortedDesc ? "desc" : "asc" : ""}`}/>
                ) : ""}
              </>
            </div>
            <div>{column.canFilter ? column.render('Filter') : null}</div>
          </th>
        )
      })}
    </tr>
  )
}

export const TRBody = ({page, lastItemBold, length, prepareRow}) => {
  const {t} = useTranslation();

  return (
    <>{page && page.length ? page.map((row, index) => {
      prepareRow(row);
      const isSelfRegClass = row?.original?.isSelfReg || row?.original?.isFirstCreation ? "self-reg-row": "";
      return (
        <tr {...row.getRowProps()}
            className={`${lastItemBold && page.length === index + 1 ? "font-weight-medium" : ""}
            ${row.original.isAverage ? "bg-grey2" : ""}
            ${isSelfRegClass}
            `}>
          {row.cells.map(cell => {
            return <td {...cell.getCellProps()}
                       style={
                         page.length === 1 ? {
                           paddingTop: "60px",
                           paddingBottom: "60px",
                         } : null}
                       className={`${cell.column.center ? "text-center" : ""}`}

            >{cell.render('Cell')}</td>
          })}
        </tr>
      )
    }) : <tr>
      <td colSpan={length}>{t("noData")}</td>
    </tr>}
    </>
  )
}

export function Table({
                        lastItemBold,
                        columns,
                        data,
                        pageSizeDefault,
                        exportName,
                        showToggleColumn,
                        isGlobalFilter,
                        isExport,
                        dataName,
                        hiddenColumns,
                        showResetFilterBtn
                      }) {
  const {t} = useTranslation();

  const excludedColumnsId = [...columns]?.map(item => {
    if (item?.excludeFromExport) {
      return item.accessor
    }
  }) || [];

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize, globalFilter},
    allColumns,
    getToggleHideAllColumnsProps,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      // initialState: {pageIndex: 0, pageSize: pageSizeDefault},
      initialState: {
        pageSize: pageSizeDefault,
        hiddenColumns: [...hiddenColumns],
      },
      // resetState,
      autoResetPage: false,
    },

    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 20)


  // const maxHeightTableWrapper = "290";
  const maxHeightTableWrapper = "120";

  return (
    <>
      <div className={"d-flex bg-white p-3"}>
        {isGlobalFilter ? <>
          <div
            style={{
              textAlign: 'left',
            }}
            className={"mr-4"}
          >
            <GlobalFilter
              gotoPage={gotoPage}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />

          </div>
        </> : null}
        {rows.length > pageSize && <Pagination
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pageCount={pageCount}
          canNextPage={canNextPage}
          nextPage={nextPage}
        />}
        {isExport &&
          <button className={"btn btn-outline-primary ml-auto"}
                  onClick={() => getExcel(exportName, headerGroups, rows, prepareRow, excludedColumnsId)}>{t("exportToExcel")}</button>}
      </div>

      <div className={"filtered-table-wrapper"}
           style={{maxHeight: `calc(100vh - ${maxHeightTableWrapper}px)`}}
      >
        <BTable striped bordered hover size="sm" className={"bg-white filtered-table"} {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => <TRHead
            headerGroup={headerGroup} {...headerGroup.getHeaderGroupProps()}/>)}
          </thead>
          <tbody {...getTableBodyProps()}>
          <TRBody page={page} lastItemBold={lastItemBold} length={headerGroups[0].headers.length}
                  prepareRow={prepareRow}/>
          {/*{page && page.length ? page.map((row, index) => {*/}
          {/*  prepareRow(row)*/}
          {/*  return (*/}
          {/*    <tr {...row.getRowProps()}*/}
          {/*        className={`${lastItemBold && page.length === index + 1 ? "font-weight-medium" : ""}`}>*/}
          {/*      {row.cells.map(cell => {*/}
          {/*        return <td {...cell.getCellProps()}*/}
          {/*                   style={*/}
          {/*                     page.length === 1 ? {*/}
          {/*                       paddingTop: "60px",*/}
          {/*                       paddingBottom: "60px",*/}
          {/*                     } : null}*/}
          {/*                   className={`${cell.column.center ? "text-center" : ""}`}*/}

          {/*        >{cell.render('Cell')}</td>*/}
          {/*      })}*/}
          {/*    </tr>*/}
          {/*  )*/}
          {/*}) : <tr>*/}
          {/*  <td colSpan={headerGroups[0].headers.length}>Нет данных</td>*/}
          {/*</tr>}*/}

          </tbody>
        </BTable>
      </div>

      <div className={"column-table-filter d-flex justify-content-between bg-white"}>
        {/*<div className={"pb-3"}>*/}
        {/*  <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} className={"mr-1"}/>*/}
        {/*  Сбросить все столбцы*/}
        {/*</div>*/}

        {showToggleColumn && <div className={`d-flex py-3 pl-3`}>
          <div className={"text-grey5 pr-2 pt-2 text-nowrap"}>{t("showColumn")}</div>
          <MultiSelectColumns
            allColumns={allColumns}
            dataName={dataName}
          />
        </div>}


        {(showResetFilterBtn) && <div className={"p-3 text-right"}>
          {showResetFilterBtn && <button
            onClick={() => setAllFilters([])}
            className={"btn btn-outline-primary d-block mb-2"}>
            <img src={ResetSettings} alt={t("resetFilter")} className={"mr-2"}/>
            {t("resetFilter")}
          </button>}
        </div>}
      </div>

      {/*{rows.length > pageSize && (<div className={"d-flex justify-content-between align-items-center"}>*/}
      {/*  /!*<div className={""}>*!/*/}
      {/*  /!*  Показаны с {pageIndex !== 0 ? pageSize * (pageIndex + 1) - pageSize + 1 : 1} {" "}*!/*/}
      {/*  /!*  по {pageSize * (pageIndex + 1) <= rows.length ? pageSize * (pageIndex + 1) : rows.length} {" "}*!/*/}
      {/*  /!*  из {rows.length} записей*!/*/}
      {/*  /!*</div>*!/*/}

      {/*  <div className="pagination d-flex">*/}
      {/*       <span className={"mr-1 d-inline-flex align-items-center"}>*/}
      {/*    Страница {' '}*/}
      {/*         <strong className={"mx-1"}>*/}
      {/*  {pageIndex + 1} из {pageOptions.length}*/}
      {/*    </strong>{' '}*/}
      {/*    </span>*/}
      {/*    /!*<div className={"mx-1 d-flex align-items-center position-relative"}>|*!/*/}
      {/*    /!*  <label className={"mb-0 ml-2"}> Перейти на страницу:</label>*!/*/}
      {/*    /!*  <input*!/*/}
      {/*    /!*    type="number"*!/*/}
      {/*    /!*    className={"form-control mx-1"}*!/*/}
      {/*    /!*    defaultValue={pageIndex + 1}*!/*/}
      {/*    /!*    onChange={e => {*!/*/}
      {/*    /!*      const page = e.target.value ? Number(e.target.value) - 1 : 0*!/*/}
      {/*    /!*      gotoPage(page)*!/*/}
      {/*    /!*    }}*!/*/}
      {/*    /!*    style={{width: '80px'}}*!/*/}
      {/*    /!*  />*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    {' '}*/}
      {/*    /!*<select*!/*/}
      {/*    /!*  value={pageSize}*!/*/}
      {/*    /!*  className={"form-control mx-1"}*!/*/}
      {/*    /!*  style={{width: "150px"}}*!/*/}
      {/*    /!*  onChange={e => {*!/*/}
      {/*    /!*    setPageSize(Number(e.target.value))*!/*/}
      {/*    /!*  }}*!/*/}
      {/*    /!*>*!/*/}
      {/*    /!*  {[1, 2, 3, 5, 10, 20, 30, 40, 50].map(pageSize => (*!/*/}
      {/*    /!*    <option key={pageSize} value={pageSize}>*!/*/}
      {/*    /!*      Показывать по {pageSize}*!/*/}
      {/*    /!*    </option>*!/*/}
      {/*    /!*  ))}*!/*/}
      {/*    /!*</select>*!/*/}
      {/*  </div>*/}
      {/*</div>)}*/}

    </>
  )
}

export const FilteredTable = ({
                                lastItemBold = false,
                                dataFromComponent = false,
                                showResetFilterBtn = false,
                                dataName,
                                dataBody = null,
                                columns,
                                isGlobalFilter = false,
                                isExport = false,
                                showToggleColumn = false,
                                pageSizeDefault = 10,
                                // pageSizeDefault = 5,
                                exportName = "",
                                hiddenColumns = []
                              }) => {

  const data = useSelector(getTableData)[dataName] || [];
  const {isLoad} = useSelector((state) => state.tableData);
  const dispatch = useDispatch();

  useEffect(() => {
    let _isMount = true;
    _isMount && !dataFromComponent && dispatch(loadTableData(dataName, (dataBody))).then((res) => res && dispatch(loadTableData("sidebarInfo")));
    return () => (_isMount = false);
  }, [dataName, dispatch]);


  if (isLoad) {
    return <Load/>
  }

  return (
    <Table columns={columns}
           ComponentAfter
           lastItemBold={lastItemBold}
           data={dataFromComponent ? dataFromComponent : data}
           pageSizeDefault={pageSizeDefault}
           isGlobalFilter={isGlobalFilter}
           isExport={isExport}
           exportName={exportName}
           dataName={dataName}
           hiddenColumns={hiddenColumns}
           showResetFilterBtn={showResetFilterBtn}
           showToggleColumn={showToggleColumn}
    />

  )
}